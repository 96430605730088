<template>
  <v-app>
    <v-main>
      <ListUsers></ListUsers>
    </v-main>
  </v-app>
</template>

<script>
import ListUsers from "@/components/ListUsers.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    ListUsers,
  },
};
</script>
