<template>
  <ListUsers />
</template>

<script>
  import ListUsers from '../components/ListUsers'

  export default {
    name: 'ArgosView',

    components: {
      ListUsers,
    },
  }
</script>
