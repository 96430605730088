import UsersService from "@/services/UsersService";
// Initial state
const state = {
  allUsers: [],
};
// Getters
const getters = {
  getAllUsers: (state) => {
    return state.allUsers;
  },
};
// Actions
const actions = {
  getUsers({ commit }) {
    UsersService.getAll().then((response) => {
      console.log(response.data.data);
      commit("setAllUsers", response.data.data);
    });
  },
};
// Mutations
const mutations = {
  setAllUsers(state, Users) {
    state.allUsers = Users;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
