<template>
  <v-container fluid>
    <v-card>
      <v-card-title> Environnement </v-card-title>
      <v-card-text>
        <v-select
          :items="env"
          v-model="envSelected"
          label="Environnement"
        ></v-select>
      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-title> Connexion </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="identifiant"
              placeholder="test.eprotec2"
              label="Identifiant"
            ></v-text-field>
            <v-text-field
              v-model="mdp"
              placeholder="P@sw0rd"
              type="password"
              label="Mot de passe"
            ></v-text-field>
            <v-btn @click="connection">Se connecter</v-btn>
            <p v-if="connected">Connecté</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-title> Recherche </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="args.search"
              placeholder="Nom, prénom, mail... ou vide"
              label="Champs de recherche (nom, prénom, mail... ou laisser vide pour tout afficher)"
            ></v-text-field>
            <v-text-field
              v-model.number="args.limit"
              placeholder="Nb maximum (pas de limite)"
              label="Nb éléments maximum (par défaut 50, pas de limite haute)"
            ></v-text-field>
            <v-btn @click="search" :disabled="!connected">Rechercher</v-btn>
            <p v-if="!connected">
              Connectez-vous avant d'utiliser la recherche
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ this.displayed }} éléments affichés sur
            {{ this.total }} disponibles.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-title> Résultats </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Filtre"
          hide-details
        ></v-text-field>
        <br />
        <v-data-table
          :headers="headers"
          :items="users"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 1000, -1],
          }"
          :search="filter"
          :loading="loading"
          loading-text="Téléchargement en cours..."
          no-data-text="Pas de donnée à afficher"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "ListUsers",
  data() {
    return {
      users: [],
      env: ["Production", "Pré-production"],
      envSelected: "Production",
      identifiant: "test.eprotec2",
      mdp: "",
      token: {},
      connected: false,
      args: {
        search: "",
        limit: "",
      },
      filter: "",
      displayed: 0,
      total: 0,
      loading: false,
      headers: [
        {
          text: "Prénom",
          sortable: true,
          filterable: true,
          value: "firstName",
        },
        { text: "Nom", sortable: true, filterable: true, value: "lastName" },
        { text: "Mail", sortable: true, filterable: true, value: "email" },
        { text: "Tél", sortable: true, filterable: true, value: "phone" },
        {
          text: "Section",
          sortable: true,
          filterable: true,
          value: "sections[0].name",
        },
        { text: "Role", sortable: true, filterable: true, value: "role" },
      ],
    };
  },
  methods: {
    search() {
      this.users = [];
      this.loading = true;
      let url = "https://cors.f4iil.fr/argos-api-preprod/";
      if (this.envSelected === "Production") {
        url = "https://cors.f4iil.fr/argos-api/";
      }
      axios
        .get(
          url +
            "users?search=" +
            this.args.search +
            "&limit=" +
            this.args.limit,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.users = response.data.data;
          this.displayed = this.users.length;
          this.total = response.data.totalItems;
          this.loading = false;
        })
        .catch(() => {
          console.log("Erreur de recherche");
        });
    },
    connection() {
      let url = "https://cors.f4iil.fr/argos-api-preprod/";
      if (this.envSelected === "Production") {
        url = "https://cors.f4iil.fr/argos-api/";
      }
      axios
        .post(
          url + "auth/login",
          {
            username: this.identifiant,
            password: this.mdp,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.token = response.data.access_token;
          this.connected = true;
        })
        .catch(() => {
          console.log("Erreur de connexion");
          this.connected = false;
        });
    },
  },
};
</script>
